<template>
  <div v-if="loadOrdersFilter.startDate && loadOrdersFilter.endDate">
    <load-orders-filter
      @filter-load-orders="getTeamsList"
      @download-pdf="emitDownloadPdf"
      listType="teams"
      isList
      hasPdf
      :excelData="teamsList"
      :excelFields="excelFields"
    />
    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-btn text class="mb-6 mr-3" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
        <v-icon class="mb-6 mr-3" @click="getTeamsList">{{ icons.mdiReload }}</v-icon>
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getTeamsList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          class="list"
          :headers="headers"
          :items="teamsList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="teamsTotal"
          :mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :expanded.sync="expanded"
          item-key="key"
          show-expand
          height="72vh"
          fixed-header
          :item-class="rowClasses"
        >
          <template v-slot:header.uTarefaFilter>
            <wot-filter-menu :items="getTeamsListValue('u_tarefa')" fieldName="u_tarefa"/>
          </template>
          <template v-slot:header.uTiposFilter>
            <wot-filter-menu :items="getTeamsListValue('u_tipos')" fieldName="u_tipos"/>
          </template>
          <template v-slot:header.cargaFilter>
            <wot-filter-menu :items="getTeamsListValue('carga')" fieldName="carga"/>
          </template>
          <template v-slot:header.uPrcFilter>
            <wot-filter-menu
              :items="getTeamsListValue('u_prc')"
              fieldName="u_prc"
              format="currency"
            />
          </template>
          <template v-slot:item.dataobra="{ value }">{{ formatUnix(value) }}</template>
          <template v-slot:item.u_tarefa="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noUTarefa') }}</span>
          </template>
          <template v-slot:item.u_quant2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index: item.index , value: val, field: 'u_quant2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="`${value} ${item.unidade}`">
                {{ item.u_quant2 }} {{ item.unidade }}
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_quant2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_quant2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.="{ value }">
            <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
          </template>
          <template v-slot:item.u_prc="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index: item.index, value: val, field: `u_prc${item.list}`, bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prc" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prc')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.total="{ value }">
            {{ $n(value, 'currency') }}
          </template>
          <template v-slot:item.u_ftequip="{ index, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = item[ftEquipaField(item.list)]"
              @save="inlineSave({
                index: item.index,
                value: val,
                field: ftEquipaField(item.list),
                bostamp: item.bostamp
              })"
            >
              <wot-list-item
                :item="(item[ftEquipaField(item.list)]) || 'truthy'"
              >
                <span v-if="item[ftEquipaField(item.list)]">
                  {{ item[ftEquipaField(item.list)] }}
                </span>
                <span class="info--text" v-else>{{ $t('noFt') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_ftequip">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.u_ftequip')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="15"
                    maxlength="15"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn
              color="primary"
              icon
              :to="{ name: 'editLoadOrder', params: { bostamp: item.bostamp }}"
              target="_blank"
            >
              <v-icon>{{ icons.mdiFileDocumentEditOutline }}</v-icon>
            </v-btn>
            <v-badge v-if="item.anexos > 0" :content="item.anexos" color="secondary" overlap>
              <v-btn icon @click="expand(true)" v-if="!isExpanded">
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
              <v-btn icon @click="expand(false)" v-if="isExpanded">
                <v-icon>{{ icons.mdiChevronUp }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <attachments
                class="mt-3 mb-3 text-right"
                :bostamp="item.bostamp"
                @open-pdf="openPdf"
                @reload-list="getTeamsList"
              />
            </td>
          </template>
          <template slot="body.append">
            <tr>
              <td colspan="9"></td>
              <td
                v-for="(item, index) in sumTeamsQuant"
                :key="index"
                class="text-right"
              >
                {{ displayTotal(item) }}
              </td>
              <td></td>
              <td class="text-right">{{ $n(sumTeamsTotal, 'currency') }}</td>
              <td colspan="2"></td>
            </tr>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ $t('pageText', [pageStart, pageStop, itemsLength]) }} <br />
            {{ $t('loadOrdersCount', [teamsListCountOrder]) }} <br />
            {{ $t('linesCount', [teamsList.length - filteredStamps.length]) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <wot-send-list
      v-if="emailDialog"
      :count="teamsTotal"
      :emails="emails"
      :options="options"
      :keyword="search"
      type="teams"
    />
    <v-dialog
      v-model="pdfDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="pdfDialog = false"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="pdfDialog = false"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
          <v-toolbar-title>{{ titleDialog }}</v-toolbar-title>
        </v-toolbar>
        <vue-pdf-app :pdf="fileName"></vue-pdf-app>
      </v-card>
    </v-dialog>
    <link rel="resource" type="application/l10n" :href="`/pdf.js/${lang}/viewer.properties`">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiMagnify,
  mdiFileDocumentEditOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiReload,
  mdiClose,
} from '@mdi/js';
import { ValidationProvider } from 'vee-validate';
import { itemsPerPage } from '@/resources/options';
import { teamsListHeaders } from '@/resources/headers';
import functions from '@/resources/functions';

export default {
  metaInfo() {
    return {
      title: this.$t('teamsList'),
    };
  },
  mixins: [functions],
  components: {
    ValidationProvider,
    LoadOrdersFilter: () => import(/* webpackChunkName: "filter" */ '@/components/filters/LoadOrdersFilter.vue'),
    WotListItem: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotListItem.vue'),
    WotFilterMenu: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotFilterMenu.vue'),
    WotSendList: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotSendList.vue'),
    Attachments: () => import(/* webpackChunkName: "attachment" */ '../Attachments.vue'),
    VuePdfApp: () => import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app'),
  },
  data() {
    return {
      lang: process.env.VUE_APP_I18N_LOCALE,
      pdfKey: 0,
      itemsPerPage,
      search: '',
      options: {},
      headers: teamsListHeaders,
      expanded: [],
      titleDialog: '',
      fileName: null,
      pdfDialog: false,
      icons: {
        mdiMagnify,
        mdiFileDocumentEditOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiReload,
        mdiClose,
      },
      excelFields: {
        [this.$t('fields.obrano')]: 'obrano',
        [this.$t('fields.dataobra')]: {
          field: 'dataobra',
          callback: (value) => this.formatUnix(value),
        },
        [this.$t('fields.u_tarefa')]: 'u_tarefa',
        [this.$t('fields.u_matric')]: 'u_matric',
        [this.$t('fields.u_tipos')]: 'u_tipos',
        [this.$t('fields.carga')]: 'carga',
        [this.$t('fields.u_quant2')]: 'u_quantity',
        [this.$t('fields.u_prc')]: 'u_prc',
        [this.$t('fields.total')]: 'total',
        [this.$t('fields.u_equipa')]: 'u_equipa',
        [this.$t('fields.u_ftequip')]: 'u_ftequip',
      },
    };
  },
  computed: {
    ...mapGetters('loadOrders', ['teamsList', 'teamsTotal', 'sumTeamsTotal', 'filterUEquipaEmail', 'emailDialog', 'sumTeamsQuant', 'loadOrdersFilter', 'getTeamsListValue', 'teamsListCountOrder']),
    ...mapGetters(['isLoading']),
    ...mapFields('loadOrders', {
      uTarefaFilter: 'filterMenu.u_tarefa',
      uTiposFilter: 'filterMenu.u_tipos',
      uPrcFilter: 'filterMenu.u_prc',
      cargaFilter: 'filterMenu.carga',
    }),
    ...mapFields('loadOrders', ['filteredStamps']),
    emails() {
      return this.explodeEmails(this.filterUEquipaEmail);
    },
  },
  watch: {
    options: {
      handler() {
        this.getTeamsList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchServerTime']),
    ...mapActions('loadOrders', ['showTeamsList', 'downloadPdf', 'updateOrdersListValue']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setFilteredStamps']),
    clearFilters() {
      this.uTarefaFilter = [];
      this.uTiposFilter = [];
      this.uPrcFilter = [];
      this.cargaFilter = [];
    },
    rowClasses(item) {
      const stamps = this.filteredStamps.slice();

      if (
        this.uTarefaFilter.includes(item.u_tarefa)
        || this.uTiposFilter.includes(item.u_tipos)
        || this.uPrcFilter.includes(item.u_prc)
        || this.cargaFilter.includes(item.carga)
      ) {
        if (!stamps.includes(item.key)) {
          this.filteredStamps = [...stamps, item.key];
        }

        return 'd-none';
      }

      if (stamps.includes(item.key)) {
        const index = stamps.indexOf(item.key);

        stamps.splice(index, 1);

        this.setFilteredStamps(stamps);
      }

      return '';
    },
    ftEquipaField(list) {
      let field = '';

      switch (list) {
        case 1:
          field = 'u_ftequipa';
          break;
        case 2:
          field = 'u_ftequip2';
          break;
        case 3:
          field = 'u_ftequip3';
          break;
        default:
          field = '';
      }

      return field;
    },
    openPdf({ fileName, title }) {
      this.pdfKey += 1;

      this.titleDialog = title;
      this.fileName = fileName;

      this.pdfDialog = true;
    },
    async getTeamsList() {
      this.setLoading(true);

      await this.showTeamsList({ ...this.options, keyword: this.search });

      this.setLoading(false);
    },
    async emitDownloadPdf() {
      this.setLoading(true);

      this.downloadPdf({ ...this.options, keyword: this.search, type: 'teams' });

      this.setLoading(false);
    },
    async inlineSave(obj) {
      this.setLoading(true);

      await this.updateOrdersListValue({ ...obj, type: 'teams' });

      this.setLoading(false);

      await this.getTeamsList();
    },
  },
  async mounted() {
    await this.fetchServerTime();
  },
};
</script>
