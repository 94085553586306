<template>
  <div v-if="loadOrdersFilter.startDate && loadOrdersFilter.endDate">
    <load-orders-filter
      @filter-load-orders="getLoadOrdersList"
      @download-pdf="emitDownloadPdf"
      listType="global"
      hasPdf
      :excelData="loadOrdersList"
      :excelFields="excelFields"
    />
    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-btn text class="mb-6 mr-3" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
        <v-icon class="mb-6 mr-3" @click="getLoadOrdersList">{{ icons.mdiReload }}</v-icon>
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getLoadOrdersList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          class="list"
          :headers="headers"
          :items="loadOrdersList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="loadOrdersTotal"
          :mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :expanded.sync="expanded"
          item-key="bostamp"
          show-expand
          height="72vh"
          fixed-header
          :item-class="rowClasses"
        >
          <template v-slot:header.uTranspFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_transp')" fieldName="u_transp"/>
          </template>
          <template v-slot:header.nomeFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('nome')" fieldName="nome"/>
          </template>
          <template v-slot:header.uTiposFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_tipos')" fieldName="u_tipos"/>
          </template>
          <template v-slot:header.uCargaFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_carga')" fieldName="u_carga"/>
          </template>
          <template v-slot:header.uCarga2Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_carga2')" fieldName="u_carga2"/>
          </template>
          <template v-slot:header.uDescargaFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_descarga')" fieldName="u_descarga"/>
          </template>
          <template v-slot:header.uDescarg2Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_descarg2')" fieldName="u_descarg2"/>
          </template>
          <template v-slot:header.unidadeFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('unidade')" fieldName="unidade"/>
          </template>
          <template v-slot:header.uMataFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_mata')" fieldName="u_mata"/>
          </template>
          <template v-slot:header.uFttranspFilter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_fttransp')" fieldName="u_fttransp"/>
          </template>
          <template v-slot:header.uEquipa1Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_equipa1')" fieldName="u_equipa1"/>
          </template>
          <template v-slot:header.uTarefa1Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_tarefa1')" fieldName="u_tarefa1"/>
          </template>
          <template v-slot:header.uPrc1Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_prc1')" fieldName="u_prc1"/>
          </template>
          <template v-slot:header.uEquipa2Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_equipa2')" fieldName="u_equipa2"/>
          </template>
          <template v-slot:header.uTarefa2Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_tarefa2')" fieldName="u_tarefa2"/>
          </template>
          <template v-slot:header.uPrc2Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_prc2')" fieldName="u_prc2"/>
          </template>
          <template v-slot:header.uEquipa3Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_equipa3')" fieldName="u_equipa3"/>
          </template>
          <template v-slot:header.uTarefa3Filter>
            <wot-filter-menu :items="getLoadOrdersListValue('u_tarefa3')" fieldName="u_tarefa3"/>
          </template>
          <template v-slot:header.uPrc3Filter>
            <wot-filter-menu
              :items="getLoadOrdersListValue('u_prc3')"
              fieldName="u_prc3"
              format="currency"
            />
          </template>
          <template v-slot:item.dataobra="{ value }">{{ formatUnix(value) }}</template>
          <template v-slot:item.u_cepos="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_cepos', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_cepos" rules="required">
                  <v-text-field
                    v-model="val"
                    :label="`${$t('fields.u_cepos')} *`"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="40"
                    maxlength="40"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_tipos="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = item.ststamp"
              @save="updateUtipos({ index, value: val, field: 'u_tipos', bostamp: item.bostamp })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_tipos" rules="required">
                  <v-autocomplete
                    v-model="val"
                    item-text="design"
                    item-value="ststamp"
                    :items="cargoTypes"
                    :label="`${$t('fields.u_tipos')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.unidade="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'unidade', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="unidade" rules="required">
                  <v-autocomplete
                    v-model="val"
                    :items="units"
                    :label="`${$t('fields.unidade')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.plate="{ index, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openPlates(item.u_transp, item.u_matric)"
              @save="inlineSave({
                index, value: active.plate, field: 'u_matric', bostamp: item.bostamp
              })"
            >
              {{ item.u_matric }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_matric" rules="required">
                  <v-autocomplete
                    v-model="active.plate"
                    :items="plates"
                    item-text="u_matric"
                    item-value="u_matric"
                    :label="`${$t('fields.u_matric')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
            <v-divider/>
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openTrailers(item.u_transp, item.u_reboq)"
              @save="inlineSave({
                index, value: active.trailer, field: 'u_reboq', bostamp: item.bostamp
              })"
            >
              {{ item.u_reboq }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_reboq" rules="required">
                  <v-autocomplete
                    v-model="active.trailer"
                    :items="trailers"
                    item-text="u_reboq"
                    item-value="u_reboq"
                    :label="`${$t('fields.u_reboq')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_matric="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openPlates(item.u_transp, value)"
              @save="inlineSave({
                index, value: active.plate, field: 'u_matric', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_matric" rules="required">
                  <v-autocomplete
                    v-model="active.plate"
                    :items="plates"
                    item-text="u_matric"
                    item-value="u_matric"
                    :label="`${$t('fields.u_matric')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_carga="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_carga', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_carga" rules="required">
                  <v-autocomplete
                    v-model="val"
                    :items="addresses"
                    :label="`${$t('fields.u_carga')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_carga2="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_carga2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_carga2">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.u_carga2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="120"
                    maxlength="120"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_descarga="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openDownloadAddresses(item.clstamp, value)"
              @save="inlineSave({
                index, value: active.downloadAddress, field: 'u_descarga', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_descarga" rules="required">
                  <v-autocomplete
                    v-model="active.downloadAddress"
                    :items="addresses"
                    item-text="szadrsdesc"
                    item-value="szadrsdesc"
                    :label="`${$t('fields.u_descarga')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_descarg2="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_descarg2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_descarg2">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.u_descarg2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="120"
                    maxlength="120"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_quant2="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_quant2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_quant2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_quant2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_mata="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openWoods(value)"
              @save="updateUMata({
                index, value: active.wood, field: 'u_mata', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_mata">
                  <v-autocomplete
                    v-model="active.wood"
                    :items="woods"
                    :label="$t('fields.u_mata')"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.final="{ value }">
            <span :class="value < 0 ? 'error--text' : ''">{{ $n(value, 'currency') }}</span>
          </template>
          <template v-slot:item.lucro="{ value }">
            <span :class="value < 0 ? 'error--text' : ''">{{ $n(value, 'currency') }}</span>
          </template>
          <template v-slot:item.u_equipa1="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_equipa1', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_equipa1">
                  <v-autocomplete
                    v-model="val"
                    :items="teams"
                    item-text="nome"
                    item-value="nome"
                    :label="$t('fields.u_equipa1')"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_tarefa1="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_tarefa1', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_tarefa1">
                  <v-autocomplete
                    v-model="val"
                    :items="tasks"
                    :label="$t('fields.u_tarefa1')"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prc1="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prc1', bostamp: item.bostamp
              })"
            >
              <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prc1" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prc1')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_equipa2="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_equipa2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_equipa2">
                  <v-autocomplete
                    v-model="val"
                    :items="teams"
                    item-text="nome"
                    item-value="nome"
                    :label="$t('fields.u_equipa2')"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_tarefa2="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_tarefa2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_tarefa2">
                  <v-autocomplete
                    v-model="val"
                    :items="tasks"
                    :label="`${$t('fields.u_tarefa2')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prc2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prc2', bostamp: item.bostamp
              })"
            >
              <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prc2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prc2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prcl="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prcl', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prcl" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prcl')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_bonus="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_bonus', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_bonus" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_bonus')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_fttrans="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_fttrans', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_fttrans" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_fttrans')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.nft="{ item, value }">
            <v-edit-dialog
              v-if="value === null"
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = item.u_ftflore2"
              @save="inlineSave({
                index, value: val, field: 'u_ftflore2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="item.u_ftflore2">
                <span>{{ item.u_ftflore2 }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.nft')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="120"
                    maxlength="120"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
            <span v-else>
              {{ value === 'anulada' ? $t('nullInvoice') : value }}
            </span>
          </template>
          <template v-slot:item.nft1="{ item, value }">
            <v-edit-dialog
              v-if="value === null"
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = item.u_fttrans2"
              @save="inlineSave({
                index, value: val, field: 'u_fttrans2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="item.u_fttrans2">
                <span>{{ item.u_fttrans2 }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.nft')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="120"
                    maxlength="120"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
            <span v-else>
              {{ value === 'anulada' ? $t('nullInvoice') : value }}
            </span>
          </template>
          <template v-slot:item.u_prtransp="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prtransp', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prtransp" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prtransp')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_fttransp="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noFt') }}</span>
          </template>
          <template v-slot:item.u_comiss="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_comiss', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_comiss')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.at_document="{ value, item }">
            <span v-if="value && value.atcodeid">{{ value.atcodeid }}</span>
            <span class="warning--text"
              v-else-if="value && value.atcodeid === '' && value.codigo === '-100'"
            >
              {{ $t('wot.atNoCode') }}
            </span>
            <span class="error--text"
              v-else-if="
                value
                && value.atcodeid === ''
                && value.codigo === '-100'
                && value.codigo !== '0'
              "
            >
              {{ value.descricao }}
            </span>
            <v-btn
              color="primary"
              icon
              v-else-if="!item.u_ncodat"
              :to="{ name: 'createDeliveryNote', params: { bostamp: item.bostamp } }"
            >
              <v-icon>{{ icons.mdiTruck }}</v-icon>
            </v-btn>
            <span v-else class="info--text">{{ $t('noAtDoc') }}</span>
          </template>
          <template v-slot:item.u_equipa3="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_equipa3', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_equipa3">
                  <v-autocomplete
                    v-model="val"
                    :items="teams"
                    item-text="nome"
                    item-value="nome"
                    :label="$t('fields.u_equipa3')"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_tarefa3="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_tarefa3', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_tarefa3">
                  <v-autocomplete
                    v-model="val"
                    :items="tasks"
                    :label="`${$t('fields.u_tarefa3')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prc3="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prc3', bostamp: item.bostamp
              })"
            >
              <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prc3" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prc3')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn
              color="primary"
              icon
              :to="{ name: 'editLoadOrder', params: { bostamp: item.bostamp }}"
              target="_blank"
            >
              <v-icon>{{ icons.mdiFileDocumentEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              :color="item.u_pwemail ? 'success': 'primary'"
              icon
              @click="openEmailModal(item)"
            >
              <v-icon>{{ icons.mdiEmailSend }}</v-icon>
            </v-btn>
            <v-btn color="primary" icon @click="openAttachModal(item)">
              <v-icon>{{ icons.mdiAttachment }}</v-icon>
            </v-btn>
            <v-badge v-if="item.anexos > 0" :content="item.anexos" color="secondary" overlap>
              <v-btn icon @click="expand(true)" v-if="!isExpanded">
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
              <v-btn icon @click="expand(false)" v-if="isExpanded">
                <v-icon>{{ icons.mdiChevronUp }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <attachments
                class="mt-3 mb-3 text-right"
                :bostamp="item.bostamp"
                @open-pdf="openPdf"
                @reload-list="getLoadOrdersList"
              />
            </td>
          </template>
          <template slot="body.append">
            <tr>
              <td colspan="19"></td>
              <td v-for="(item, index) in sumLoadOrdersQuant" :key="index">
                {{ displayTotal(item) }}
              </td>
              <td colspan="6"></td>
              <td class="text-right">{{ $n(sumLoadOrdersBonus, 'currency') }}</td>
              <td colspan="11"></td>
              <td class="text-right">
                <span
                  v-if="!roles.includes('ELucro')"
                  :class="sumLoadOrdersLucro < 0 ? 'error--text' : ''"
                >
                  {{ $n(sumLoadOrdersLucro, 'currency') }}
                </span>
              </td>
              <td colspan="18"></td>
            </tr>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ $t('pageText', [pageStart, pageStop, itemsLength]) }} <br />
            {{ $t('loadOrdersCount', [itemsLength - filteredStamps.length]) }}
          </template>
          <template v-slot:item.u_kickback="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_kickback', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_kickback" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_kickback')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_vconsul2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_vconsul2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_vconsul2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_vconsul2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_vconsul3="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_vconsul3', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_vconsul3" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_vconsul3')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_agent="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openUAgent(value)"
              @save="inlineSave({
                index, value: active.u_agent, field: 'u_agent', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_agent">
                  <v-autocomplete
                    v-model="active.u_agent"
                    :items="agents"
                    item-text="nome2"
                    item-value="nome2"
                    :label="`${$t('fields.u_agent')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_interme2="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openUAgent(value)"
              @save="inlineSave({
                index, value: active.u_interme2, field: 'u_interme2', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_interme2">
                  <v-autocomplete
                    v-model="active.u_interme2"
                    :items="agents"
                    item-text="nome2"
                    item-value="nome2"
                    :label="`${$t('fields.u_interme2')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_interme3="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openUAgent(value)"
              @save="inlineSave({
                index, value: active.u_interme3, field: 'u_interme3', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_interme3">
                  <v-autocomplete
                    v-model="active.u_interme3"
                    :items="agents"
                    item-text="nome2"
                    item-value="nome2"
                    :label="`${$t('fields.u_interme3')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <email-dialog
      v-if="emailDialog"
      :item="active.item"
    />
    <attachments-dialog
      v-if="attachmentsDialog"
      :bostamp="active.item.bostamp"
      @reload-list="getLoadOrdersList"
    />
    <v-dialog
      v-model="pdfDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="pdfDialog = false"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="pdfDialog = false"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
          <v-toolbar-title>{{ titleDialog }}</v-toolbar-title>
        </v-toolbar>
        <vue-pdf-app :pdf="fileName"></vue-pdf-app>
      </v-card>
    </v-dialog>
    <link rel="resource" type="application/l10n" :href="`/pdf.js/${lang}/viewer.properties`">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiPencilOutline,
  mdiAccountSearch,
  mdiTruck,
  mdiFileDocumentEditOutline,
  mdiEmailSend,
  mdiAttachment,
  mdiChevronDown,
  mdiChevronUp,
  mdiReload,
  mdiClose,
} from '@mdi/js';
import { ValidationProvider } from 'vee-validate';
import { itemsPerPage } from '@/resources/options';
import { loadOrdersGlobalListHeaders } from '@/resources/headers';
import 'vue-pdf-app/dist/icons/main.css';
import functions from '@/resources/functions';

export default {
  metaInfo() {
    return {
      title: this.$t('globalList'),
    };
  },
  mixins: [functions],
  components: {
    ValidationProvider,
    LoadOrdersFilter: () => import(/* webpackChunkName: "filter" */ '@/components/filters/LoadOrdersFilter.vue'),
    VuePdfApp: () => import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app'),
    WotListItem: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotListItem.vue'),
    WotFilterMenu: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotFilterMenu.vue'),
    Attachments: () => import(/* webpackChunkName: "attachment" */ '../Attachments.vue'),
  },
  data() {
    return {
      lang: process.env.VUE_APP_I18N_LOCALE,
      pdfKey: 0,
      selected: [],
      titleDialog: '',
      fileName: null,
      pdfDialog: false,
      itemsPerPage,
      search: '',
      options: {},
      headers: loadOrdersGlobalListHeaders,
      active: {
        item: {},
        downloadAddress: '',
        trailer: '',
        plate: '',
        wood: '',
        u_agent: '',
        u_interme2: '',
        u_interme3: '',
      },
      expanded: [],
      icons: {
        mdiPencilOutline,
        mdiAccountSearch,
        mdiTruck,
        mdiFileDocumentEditOutline,
        mdiEmailSend,
        mdiAttachment,
        mdiChevronDown,
        mdiChevronUp,
        mdiReload,
        mdiClose,
      },
      excelFields: {
        [this.$t('fields.obrano')]: 'obrano',
        [this.$t('fields.dataobra')]: {
          field: 'dataobra',
          callback: (value) => this.formatUnix(value),
        },
        [this.$t('fields.client')]: 'nome',
        [this.$t('fields.u_tipos')]: 'u_tipos',
        [this.$t('fields.carga')]: 'carga',
        [this.$t('fields.descarga')]: 'descarga',
        [this.$t('fields.u_mata')]: 'u_mata',
        [this.$t('fields.u_quant2')]: 'u_quantity',
        [this.$t('fields.final')]: 'final',
        [this.$t('fields.profit')]: 'profit',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['roles']),
    ...mapGetters('loadOrders', ['loadOrdersList', 'loadOrdersTotal', 'transporters', 'cargoTypes', 'units', 'uploadAddresses', 'teams', 'tasks', 'downloadAddresses', 'trailers', 'plates', 'woods', 'attachments', 'woodProvider', 'emailDialog', 'attachmentsDialog', 'loadOrdersFilter', 'sumLoadOrdersLucro', 'sumLoadOrdersQuant', 'getLoadOrdersListValue', 'sumLoadOrdersBonus', 'providers']),
    ...mapGetters(['isLoading']),
    ...mapFields('loadOrders', {
      uTranspFilter: 'filterMenu.u_transp',
      nomeFilter: 'filterMenu.nome',
      uTiposFilter: 'filterMenu.u_tipos',
      uCargaFilter: 'filterMenu.u_carga',
      uCarga2Filter: 'filterMenu.u_carga2',
      uDescargaFilter: 'filterMenu.u_descarga',
      uDescarg2Filter: 'filterMenu.u_descarg2',
      unidadeFilter: 'filterMenu.unidade',
      uMataFilter: 'filterMenu.u_mata',
      uFttranspFilter: 'filterMenu.u_fttransp',
      uEquipa1Filter: 'filterMenu.u_equipa1',
      uTarefa1Filter: 'filterMenu.u_tarefa1',
      uPrc1Filter: 'filterMenu.u_prc1',
      uEquipa2Filter: 'filterMenu.u_equipa2',
      uTarefa2Filter: 'filterMenu.u_tarefa2',
      uPrc2Filter: 'filterMenu.u_prc2',
      uEquipa3Filter: 'filterMenu.u_equipa3',
      uTarefa3Filter: 'filterMenu.u_tarefa3',
      uPrc3Filter: 'filterMenu.u_prc3',
    }),
    ...mapFields('loadOrders', ['filteredStamps']),
    addresses() {
      return this.downloadAddresses.concat(this.uploadAddresses);
    },
    agents() {
      return this.providers.filter(({ nome2 }) => nome2 !== '');
    },
  },
  watch: {
    options: {
      handler() {
        this.getLoadOrdersList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchServerTime']),
    ...mapActions('loadOrders', ['showLoadOrdersList', 'updateOrdersListValue', 'showDownloadAddresses', 'showTrailers', 'showPlates', 'showWoods', 'showAttachments', 'showWoodProvider', 'downloadPdf']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setEmailDialog', 'setAttachmentsDialog', 'setFilteredStamps']),
    clearFilters() {
      this.uTranspFilter = [];
      this.nomeFilter = [];
      this.uTiposFilter = [];
      this.uCargaFilter = [];
      this.uCarga2Filter = [];
      this.uDescargaFilter = [];
      this.uDescarg2Filter = [];
      this.unidadeFilter = [];
      this.uMataFilter = [];
      this.uFttranspFilter = [];
      this.uEquipa1Filter = [];
      this.uTarefa1Filter = [];
      this.uPrc1Filter = [];
      this.uEquipa2Filter = [];
      this.uTarefa2Filter = [];
      this.uPrc2Filter = [];
      this.uEquipa3Filter = [];
      this.uTarefa3Filter = [];
      this.uPrc3Filter = [];
    },
    rowClasses(item) {
      const stamps = this.filteredStamps.slice();

      if (
        this.uTranspFilter.includes(item.u_transp)
        || this.nomeFilter.includes(item.nome)
        || this.uTiposFilter.includes(item.u_tipos)
        || this.uCargaFilter.includes(item.u_carga)
        || this.uCarga2Filter.includes(item.u_carga2)
        || this.uDescargaFilter.includes(item.u_descarga)
        || this.uDescarg2Filter.includes(item.u_descarg2)
        || this.unidadeFilter.includes(item.unidade)
        || this.uMataFilter.includes(item.u_mata)
        || this.uFttranspFilter.includes(item.u_fttransp)
        || this.uEquipa1Filter.includes(item.u_equipa1)
        || this.uTarefa1Filter.includes(item.u_tarefa1)
        || this.uPrc1Filter.includes(item.u_prc1)
        || this.uEquipa2Filter.includes(item.u_equipa2)
        || this.uTarefa2Filter.includes(item.u_tarefa2)
        || this.uPrc2Filter.includes(item.u_prc2)
        || this.uEquipa3Filter.includes(item.u_equipa3)
        || this.uTarefa3Filter.includes(item.u_tarefa3)
        || this.uPrc3Filter.includes(item.u_prc3)
      ) {
        if (!stamps.includes(item.bostamp)) {
          this.filteredStamps = [...stamps, item.bostamp];
        }

        return 'd-none';
      }

      if (stamps.includes(item.bostamp)) {
        const index = stamps.indexOf(item.bostamp);

        stamps.splice(index, 1);

        this.setFilteredStamps(stamps);
      }

      return '';
    },
    async openEmailModal(item) {
      this.setLoading(true);

      this.active.item = item;

      this.selected = [];

      const { bostamp } = item;

      await this.showAttachments(bostamp);

      this.setEmailDialog(true);

      this.setLoading(false);
    },
    openAttachModal(item) {
      this.active.item = item;

      this.setAttachmentsDialog(true);
    },
    openPdf({ fileName, title }) {
      this.pdfKey += 1;

      this.titleDialog = title;
      this.fileName = fileName;

      this.pdfDialog = true;
    },
    async openDownloadAddresses(clstamp, val) {
      this.setLoading(true);

      await this.showDownloadAddresses(clstamp);

      this.active.downloadAddress = val;

      this.setLoading(false);
    },
    async openTrailers(uTransp, val) {
      this.setLoading(true);

      await this.showTrailers(uTransp);

      this.active.trailer = val;

      this.setLoading(false);
    },
    async openPlates(uTransp, val) {
      await this.showPlates(uTransp);

      this.active.plate = val;
    },
    async openWoods(val) {
      this.setLoading(true);

      await this.showWoods();

      this.active.wood = val;

      this.setLoading(false);
    },
    async inlineSave(obj) {
      this.setLoading(true);

      await this.updateOrdersListValue(obj);

      this.setLoading(false);

      await this.getLoadOrdersList();
    },
    async updateUtipos(obj) {
      this.setLoading(true);

      const { value } = obj;

      await this.updateOrdersListValue({ ...obj, com: false });

      const i = this.cargoTypes.findIndex((x) => x.ststamp === value);

      await this.updateOrdersListValue({
        ...obj, value: this.cargoTypes[i].design, persist: false,
      });

      await this.updateOrdersListValue({ ...obj, field: 'ststamp', persist: false });

      this.setLoading(false);
    },
    async updateUMata(obj) {
      this.setLoading(true);

      const { value } = obj;

      this.updateOrdersListValue(obj);

      if (value) {
        await this.showWoodProvider(value);

        await this.updateOrdersListValue({
          ...obj, field: 'u_nmfornec', value: this.woodProvider.nome,
        });
      }

      this.setLoading(false);
    },
    async getLoadOrdersList() {
      this.setLoading(true);

      await this.showLoadOrdersList({ ...this.options, keyword: this.search });

      this.setLoading(false);
    },
    async emitDownloadPdf() {
      this.setLoading(true);

      this.downloadPdf({ ...this.options, keyword: this.search, type: 'global' });

      this.setLoading(false);
    },
    async openUAgent(val) {
      this.setLoading(true);

      this.active.u_agent = val;

      this.setLoading(false);
    },
  },
  async mounted() {
    await this.fetchServerTime();

    if (this.roles.includes('ELucro')) {
      const index = this.headers.findIndex(({ value }) => value === 'lucro');

      this.headers[index].class.push('d-none');
      this.headers[index].cellClass.push('d-none');
    }
  },
};
</script>
