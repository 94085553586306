<template>
  <div>
    <v-card elevation="24" rounded="xl" class="mt-1">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="deliveryNoteLines"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          hide-default-footer
          disable-pagination
        />
      </v-card-text>
    </v-card>
    <v-card elevation="24" rounded="xl" class="mt-3">
      <v-card-text>
        <validation-observer ref="observer" v-slot="{ passes }">
          <v-form @submit.prevent="passes(submitForm)">
            <v-row>
              <v-col cols="12" sm="6" md="2">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="expeditionDate"
                      rules="required"
                    >
                      <v-text-field
                        :value="dateFormatted"
                        :label="`${$t('fields.expeditionDate')} *`"
                        :prepend-icon="icons.mdiCalendar"
                        :hint="$t('wot.formats.date')"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                        outlined
                        dense
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="form.expeditionDate"
                    :min="minDate"
                    @input="menu = false"
                    no-title
                    show-adjacent-months
                    first-day-of-week="1"
                  >
                    <v-btn text block color="primary" @click="clickToday()">
                      {{ $t('today') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="expeditionHour"
                  :rules="{ regex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, required: true }"
                >
                  <v-text-field
                    v-model="form.expeditionHour"
                    :label="`${$t('fields.expeditionHour')} *`"
                    v-mask="'##:##'"
                    :prepend-icon="icons.mdiClock"
                    :hint="$t('wot.formats.hourMin')"
                    persistent-hint
                    :error-messages="errors"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="submitForm">{{ $t('createDeliveryNote') }}</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueMaskDirective } from 'v-mask';
import { mdiCalendar, mdiClock } from '@mdi/js';
import { deliveryNoteLines } from '@/resources/headers';
import dayjs from '@/plugins/dayjs';
import functions from '@/resources/functions';

export default {
  mixins: [functions],
  directives: { mask: VueMaskDirective },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    bostamp: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: deliveryNoteLines,
      menu: false,
      minDate: dayjs().toISOString(),
      form: {
        expeditionDate: dayjs().format('YYYY-MM-DD'),
        expeditionHour: dayjs().add(15, 'minute').format('HH:mm'),
      },
      icons: { mdiCalendar, mdiClock },
    };
  },
  computed: {
    ...mapGetters('loadOrders', ['deliveryNoteLines', 'deliveryNoteErrors']),
    ...mapGetters(['isLoading', 'alertType']),
    dateFormatted() {
      return this.formatDate(this.form.expeditionDate);
    },
  },
  methods: {
    ...mapActions('loadOrders', ['showDeliveryNoteLines', 'createDeliveryNote']),
    ...mapMutations(['setLoading']),
    clickToday() {
      this.form.expeditionDate = dayjs().format('YYYY-MM-DD');
    },
    async submitForm() {
      this.setLoading(true);

      await this.createDeliveryNote({ ...this.form, bostamp: this.bostamp });

      if (this.alertType === 'success') {
        this.$router.replace({ name: 'listLoadOrders' });
      } else {
        this.$refs.observer.setErrors(this.deliveryNoteErrors);
      }

      this.setLoading(false);
    },
  },
  async mounted() {
    this.setLoading(true);

    await this.showDeliveryNoteLines(this.bostamp);

    this.setLoading(false);
  },
};
</script>
