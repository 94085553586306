<template>
  <div v-if="loadOrdersFilter.startDate && loadOrdersFilter.endDate">
    <load-orders-filter
      @filter-load-orders="getClientList"
      @download-pdf="emitDownloadPdf"
      listType="client"
      hasPdf
      isList
      :excelData="clientList"
      :excelFields="excelFields"
    />

    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-btn text class="mb-6 mr-3" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
        <v-icon class="mb-6 mr-3" @click="getClientList">{{ icons.mdiReload }}</v-icon>
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getClientList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          class="list"
          :headers="headers"
          :items="clientList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="clientTotal"
          :mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :expanded.sync="expanded"
          item-key="bostamp"
          show-expand
          height="72vh"
          fixed-header
          :item-class="rowClasses"
        >
          <template v-slot:header.nomeFilter>
            <wot-filter-menu :items="getClientListValue('nome')" fieldName="nome"/>
          </template>
          <template v-slot:header.uTiposFilter>
            <wot-filter-menu :items="getClientListValue('u_tipos')" fieldName="u_tipos"/>
          </template>
          <template v-slot:item.dataobra="{ value }">{{ formatUnix(value) }}</template>
          <template v-slot:item.u_cepos="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_cepos', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_cepos" rules="required">
                  <v-text-field
                    v-model="val"
                    :label="`${$t('fields.u_cepos')} *`"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="40"
                    maxlength="40"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_quant2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_quant2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="`${value} ${item.unidade}`">
                {{ item.u_quant2 }} {{ item.unidade }}
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_quant2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_quant2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_comiss="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_comiss', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_comiss')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.total="{ item }">
            {{ $n(item.u_quant2 * item.u_comiss, 'currency') }}
          </template>
          <template v-slot:item.u_ftfornec="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_ftfornec', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value || 'truthy'">
                <span v-if="value">{{ value }}</span>
                <span class="info--text" v-else>{{ $t('noFt') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_ftfornec">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.u_ftfornec')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="15"
                    maxlength="15"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prcl="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prcl', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prcl" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prcl')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.nft="{ item, value }">
            <v-edit-dialog
              v-if="value === null"
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = item.u_ftflore2"
              @save="inlineSave({
                index, value: val, field: 'u_ftflore2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="item.u_ftflore2">
                <span>{{ item.u_ftflore2 }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.nft')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="120"
                    maxlength="120"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
            <span v-else>
              {{ value === 'anulada' ? $t('nullInvoice') : value }}
            </span>
          </template>
          <template v-slot:item.nft1="{ item, value }">
            <v-edit-dialog
              v-if="value === null"
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = item.u_fttrans2"
              @save="inlineSave({
                index, value: val, field: 'u_fttrans2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="item.u_fttrans2">
                <span>{{ item.u_fttrans2 }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.nft')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="120"
                    maxlength="120"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
            <span v-else>
              {{ value === 'anulada' ? $t('nullInvoice') : value }}
            </span>
          </template>
          <template v-slot:item.nft_regul="{ value }">
            <v-simple-checkbox disabled :value="value === 0"/>
          </template>
          <template v-slot:item.u_fttrans="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_fttrans', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_fttrans" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_fttrans')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_fttrans_total="{ value }">
            {{ $n(value, 'currency') }}
          </template>
          <template v-slot:item.nft1_regul="{ value }">
            <v-simple-checkbox disabled :value="value === 0"/>
          </template>
          <template v-slot:item.total_final="{ value }">
            {{ $n(value, 'currency') }}
          </template>
          <template v-slot:item.certified="{ item }">
            <v-simple-checkbox disabled :value="item.lobs !== ''"/>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn
              color="primary"
              icon
              :to="{ name: 'editLoadOrder', params: { bostamp: item.bostamp }}"
              target="_blank"
            >
              <v-icon>{{ icons.mdiFileDocumentEditOutline }}</v-icon>
            </v-btn>
            <v-badge v-if="item.anexos > 0" :content="item.anexos" color="secondary" overlap>
              <v-btn icon @click="expand(true)" v-if="!isExpanded">
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
              <v-btn icon @click="expand(false)" v-if="isExpanded">
                <v-icon>{{ icons.mdiChevronUp }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <attachments
                class="mt-3 mb-3 text-right"
                :bostamp="item.bostamp"
                @open-pdf="openPdf"
                @reload-list="getClientList"
              />
            </td>
          </template>
          <template slot="body.append">
            <tr>
              <td colspan="10"></td>
              <td v-for="(item, index) in sumClientQuant" :key="index">
                {{ displayTotal(item) }}
              </td>
              <td class="text-right">{{ $n(sumUFttransTotal, 'currency') }}</td>
              <td colspan="4"></td>
              <td class="text-right">{{ $n(sumUPrclTotal, 'currency') }}</td>
              <td colspan="2"></td>
              <td class="text-right">{{ $n(sumClientTotalFinal, 'currency') }}</td>
            </tr>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ $t('pageText', [pageStart, pageStop, itemsLength]) }} <br />
            {{ $t('loadOrdersCount', [itemsLength - filteredStamps.length]) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <wot-send-list
      v-if="emailDialog"
      :count="clientTotal"
      :emails="emails"
      :options="options"
      :keyword="search"
      type="client"
      :bostamps="bostamps"
    />
    <v-dialog
      v-model="pdfDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="pdfDialog = false"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="pdfDialog = false"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
          <v-toolbar-title>{{ titleDialog }}</v-toolbar-title>
        </v-toolbar>
        <vue-pdf-app :pdf="fileName"></vue-pdf-app>
      </v-card>
    </v-dialog>
    <link rel="resource" type="application/l10n" :href="`/pdf.js/${lang}/viewer.properties`">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';
import {
  mdiMagnify,
  mdiFileDocumentEditOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiReload,
  mdiClose,
} from '@mdi/js';
import { itemsPerPage } from '@/resources/options';
import { clientListHeaders } from '@/resources/headers';
import functions from '@/resources/functions';

export default {
  metaInfo() {
    return {
      title: this.$t('clientList'),
    };
  },
  mixins: [functions],
  components: {
    ValidationProvider,
    LoadOrdersFilter: () => import(/* webpackChunkName: "filter" */ '@/components/filters/LoadOrdersFilter.vue'),
    WotListItem: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotListItem.vue'),
    WotFilterMenu: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotFilterMenu.vue'),
    WotSendList: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotSendList.vue'),
    Attachments: () => import(/* webpackChunkName: "attachment" */ '../Attachments.vue'),
    VuePdfApp: () => import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app'),
  },
  data() {
    return {
      lang: process.env.VUE_APP_I18N_LOCALE,
      pdfKey: 0,
      itemsPerPage,
      search: '',
      options: {},
      headers: clientListHeaders,
      expanded: [],
      titleDialog: '',
      fileName: null,
      pdfDialog: false,
      icons: {
        mdiMagnify,
        mdiFileDocumentEditOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiReload,
        mdiClose,
      },
      excelFields: {
        [this.$t('fields.obrano')]: 'obrano',
        [this.$t('fields.dataobra')]: {
          field: 'dataobra',
          callback: (value) => this.formatUnix(value),
        },
        [this.$t('fields.client')]: 'nome',
        [this.$t('fields.u_cepos')]: 'u_cepos',
        [this.$t('fields.u_matric')]: 'u_matric',
        [this.$t('fields.u_tipos')]: 'u_tipos',
        [this.$t('fields.carga')]: 'carga',
        [this.$t('fields.descarga')]: 'descarga',
        [this.$t('fields.u_quant2')]: 'u_quant2',
        [this.$t('fields.u_fttrans')]: 'u_fttrans',
        [this.$t('fields.total')]: 'total',
        [this.$t('fields.nft')]: 'nft',
        [this.$t('fields.u_prcl')]: 'u_prcl',
        [this.$t('fields.total')]: 'total',
        [this.$t('fields.nft')]: 'nft',
      },
    };
  },
  computed: {
    ...mapGetters('loadOrders', ['clientList', 'clientTotal', 'loadOrdersFilter', 'getClientListValue', 'sumClientQuant', 'sumUPrclTotal', 'sumUFttransTotal', 'filterClientEmail', 'emailDialog', 'sumClientTotalFinal']),
    ...mapGetters(['isLoading']),
    ...mapFields('loadOrders', {
      nomeFilter: 'filterMenu.nome',
      uTiposFilter: 'filterMenu.u_tipos',
    }),
    ...mapFields('loadOrders', ['filteredStamps']),
    emails() {
      return this.explodeEmails(this.filterClientEmail);
    },
    bostamps() {
      return this.clientList.filter(({ anexos }) => anexos > 0)
        .map(({ bostamp }) => bostamp);
    },
  },
  watch: {
    options: {
      handler() {
        this.getClientList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchServerTime']),
    ...mapActions('loadOrders', ['showClientList', 'updateOrdersListValue', 'downloadPdf']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setFilteredStamps']),
    clearFilters() {
      this.nomeFilter = [];
      this.uTiposFilter = [];
    },
    rowClasses(item) {
      const stamps = this.filteredStamps.slice();

      if (
        this.nomeFilter.includes(item.nome)
        || this.uTiposFilter.includes(item.u_tipos)
      ) {
        if (!stamps.includes(item.bostamp)) {
          this.filteredStamps = [...stamps, item.bostamp];
        }

        return 'd-none';
      }

      if (stamps.includes(item.bostamp)) {
        const index = stamps.indexOf(item.bostamp);

        stamps.splice(index, 1);

        this.setFilteredStamps(stamps);
      }

      return '';
    },
    async getClientList() {
      this.setLoading(true);

      await this.showClientList({ ...this.options, keyword: this.search });

      this.setLoading(false);
    },
    async emitDownloadPdf() {
      this.setLoading(true);

      this.downloadPdf({ ...this.options, keyword: this.search, type: 'client' });

      this.setLoading(false);
    },
    async inlineSave(obj) {
      this.setLoading(true);

      await this.updateOrdersListValue({ ...obj, type: 'client' });

      this.setLoading(false);

      await this.getClientList();
    },
    openPdf({ fileName, title }) {
      this.pdfKey += 1;

      this.titleDialog = title;
      this.fileName = fileName;

      this.pdfDialog = true;
    },
  },
  async mounted() {
    await this.fetchServerTime();
  },
};
</script>
