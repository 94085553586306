<template>
  <div v-if="loadOrdersFilter.startDate && loadOrdersFilter.endDate">
    <load-orders-filter
      @filter-load-orders="getTransporterList"
      @download-pdf="emitDownloadPdf"
      listType="transporter"
      isList
      hasPdf
      :excelData="transporterList"
      :excelFields="excelFields"
    />
    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-btn text class="mb-6 mr-3" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
        <v-icon class="mb-6 mr-3" @click="getTransporterList">{{ icons.mdiReload }}</v-icon>
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getTransporterList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          class="list"
          :headers="headers"
          :items="transporterList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="transporterTotal"
          :mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :expanded.sync="expanded"
          item-key="bostamp"
          show-expand
          height="72vh"
          fixed-header
          :item-class="rowClasses"
        >
          <template v-slot:header.nomeFilter>
            <wot-filter-menu :items="getTransporterListValue('nome')" fieldName="nome"/>
          </template>
          <template v-slot:header.uTiposFilter>
            <wot-filter-menu :items="getTransporterListValue('u_tipos')" fieldName="u_tipos"/>
          </template>
          <template v-slot:header.cargaFilter>
            <wot-filter-menu :items="getTransporterListValue('carga')" fieldName="carga"/>
          </template>
          <template v-slot:header.descargaFilter>
            <wot-filter-menu :items="getTransporterListValue('descarga')" fieldName="descarga"/>
          </template>
          <template v-slot:item.dataobra="{ value }">{{ formatUnix(value) }}</template>
          <template v-slot:item.plate="{ index, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openPlates(item.u_transp, item.u_matric)"
              @save="inlineSave({
                index, value: active.plate, field: 'u_matric', bostamp: item.bostamp
              })"
            >
              {{ item.u_matric }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_matric" rules="required">
                  <v-autocomplete
                    v-model="active.plate"
                    :items="plates"
                    item-text="u_matric"
                    item-value="u_matric"
                    :label="`${$t('fields.u_matric')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
            <v-divider/>
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openTrailers(item.u_transp, item.u_reboq)"
              @save="inlineSave({
                index, value: active.trailer, field: 'u_reboq', bostamp: item.bostamp
              })"
            >
              {{ item.u_reboq }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_reboq" rules="required">
                  <v-autocomplete
                    v-model="active.trailer"
                    :items="trailers"
                    item-text="u_reboq"
                    item-value="u_reboq"
                    :label="`${$t('fields.u_reboq')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_cepos="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_cepos', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_cepos" rules="required">
                  <v-text-field
                    v-model="val"
                    :label="`${$t('fields.u_cepos')} *`"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="40"
                    maxlength="40"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_quant2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_quant2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="`${value} ${item.unidade}`">
                {{ item.u_quant2 }} {{ item.unidade }}
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_quant2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_quant2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prtransp="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prtransp', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prtransp" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prtransp')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.totalNVat="{ item }">
            {{ $n(item.total, 'currency') }}
          </template>
          <template v-slot:item.vat="{ item }">
            {{ $n(item.total * 0.23, 'currency') }}
          </template>
          <template v-slot:item.totalVat="{ item }">
            {{ $n(item.total * 1.23, 'currency') }}
          </template>
          <template v-slot:item.u_fttransp="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_fttransp', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value || 'truthy'">
                <span v-if="value">{{ value }}</span>
                <span class="info--text" v-else>{{ $t('noFt') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_fttransp">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.u_fttransp')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="15"
                    maxlength="15"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn
              color="primary"
              icon
              :to="{ name: 'editLoadOrder', params: { bostamp: item.bostamp }}"
              target="_blank"
            >
              <v-icon>{{ icons.mdiFileDocumentEditOutline }}</v-icon>
            </v-btn>
            <v-badge v-if="item.anexos > 0" :content="item.anexos" color="secondary" overlap>
              <v-btn icon @click="expand(true)" v-if="!isExpanded">
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
              <v-btn icon @click="expand(false)" v-if="isExpanded">
                <v-icon>{{ icons.mdiChevronUp }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <attachments
                class="mt-3 mb-3 text-right"
                :bostamp="item.bostamp"
                @open-pdf="openPdf"
                @reload-list="getTransporterList"
              />
            </td>
          </template>
          <template slot="body.append">
            <tr>
                <td colspan="14"></td>
                <td class="text-right">{{ $n(sumTransporterTotal, 'currency') }}</td>
                <td class="text-right">{{ $n(sumTransporterTotalVat, 'currency') }}</td>
                <td class="text-right">{{ $n(sumTransporterTotalWVat, 'currency') }}</td>
                <td></td>
            </tr>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ $t('pageText', [pageStart, pageStop, itemsLength]) }} <br />
            {{ $t('loadOrdersCount', [itemsLength - filteredStamps.length]) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <wot-send-list
      v-if="emailDialog"
      :count="transporterTotal"
      :emails="emails"
      :options="options"
      :keyword="search"
      type="transporter"
    />
    <v-dialog
      v-model="pdfDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="pdfDialog = false"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="pdfDialog = false"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
          <v-toolbar-title>{{ titleDialog }}</v-toolbar-title>
        </v-toolbar>
        <vue-pdf-app :pdf="fileName"></vue-pdf-app>
      </v-card>
    </v-dialog>
    <link rel="resource" type="application/l10n" :href="`/pdf.js/${lang}/viewer.properties`">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiMagnify,
  mdiFileDocumentEditOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiReload,
  mdiClose,
} from '@mdi/js';
import { ValidationProvider } from 'vee-validate';
import { itemsPerPage } from '@/resources/options';
import { transporterListHeaders } from '@/resources/headers';
import functions from '@/resources/functions';

export default {
  metaInfo() {
    return {
      title: this.$t('transportersList'),
    };
  },
  mixins: [functions],
  components: {
    ValidationProvider,
    LoadOrdersFilter: () => import(/* webpackChunkName: "filter" */ '@/components/filters/LoadOrdersFilter.vue'),
    WotListItem: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotListItem.vue'),
    WotFilterMenu: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotFilterMenu.vue'),
    WotSendList: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotSendList.vue'),
    Attachments: () => import(/* webpackChunkName: "attachment" */ '../Attachments.vue'),
    VuePdfApp: () => import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app'),
  },
  data() {
    return {
      lang: process.env.VUE_APP_I18N_LOCALE,
      pdfKey: 0,
      itemsPerPage,
      search: '',
      options: {},
      headers: transporterListHeaders,
      active: {
        trailer: '',
        plate: '',
      },
      expanded: [],
      titleDialog: '',
      fileName: null,
      pdfDialog: false,
      icons: {
        mdiMagnify,
        mdiFileDocumentEditOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiReload,
        mdiClose,
      },
      excelFields: {
        [this.$t('fields.obrano')]: 'obrano',
        [this.$t('fields.dataobra')]: {
          field: 'dataobra',
          callback: (value) => this.formatUnix(value),
        },
        [this.$t('fields.u_matric')]: 'u_matric',
        [this.$t('fields.client')]: 'nome',
        [this.$t('fields.u_cepos')]: 'u_cepos',
        [this.$t('fields.u_tipos')]: 'u_tipos',
        [this.$t('fields.carga')]: 'carga',
        [this.$t('fields.descarga')]: 'descarga',
        [this.$t('fields.u_quant2')]: 'u_quantity',
        [this.$t('fields.u_prtransp')]: 'u_prtransp',
        [this.$t('fields.totalNVat')]: 'totalNVat',
        [this.$t('fields.vat')]: 'vat',
        [this.$t('fields.totalVat')]: 'totalVat',
      },
    };
  },
  computed: {
    ...mapGetters('loadOrders', ['transporterList', 'transporterTotal', 'loadOrdersFilter', 'sumTransporterTotal', 'filterUTranspNome', 'filterUTranspEmail', 'emailDialog', 'loadOrdersFilter', 'trailers', 'plates', 'sumTransporterTotalVat', 'sumTransporterTotalWVat', 'getTransporterListValue']),
    ...mapGetters(['isLoading']),
    ...mapFields('loadOrders', {
      nomeFilter: 'filterMenu.nome',
      uTiposFilter: 'filterMenu.u_tipos',
      cargaFilter: 'filterMenu.carga',
      descargaFilter: 'filterMenu.descarga',
    }),
    ...mapFields('loadOrders', ['filteredStamps']),
    emails() {
      return this.explodeEmails(this.filterUTranspEmail);
    },
  },
  watch: {
    options: {
      handler() {
        this.getTransporterList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchServerTime']),
    ...mapActions('loadOrders', ['showTransporterList', 'downloadPdf', 'updateOrdersListValue', 'showTrailers', 'showPlates']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setFilteredStamps']),
    clearFilters() {
      this.nomeFilter = [];
      this.uTiposFilter = [];
      this.cargaFilter = [];
      this.descargaFilter = [];
    },
    rowClasses(item) {
      const stamps = this.filteredStamps.slice();

      if (
        this.nomeFilter.includes(item.nome)
        || this.uTiposFilter.includes(item.u_tipos)
        || this.cargaFilter.includes(item.carga)
        || this.descargaFilter.includes(item.descarga)
      ) {
        if (!stamps.includes(item.bostamp)) {
          this.setFilteredStamps([...stamps, item.bostamp]);
        }

        return 'd-none';
      }

      if (stamps.includes(item.bostamp)) {
        const index = stamps.indexOf(item.bostamp);

        stamps.splice(index, 1);

        this.setFilteredStamps(stamps);
      }

      return '';
    },
    openPdf({ fileName, title }) {
      this.pdfKey += 1;

      this.titleDialog = title;
      this.fileName = fileName;

      this.pdfDialog = true;
    },
    async getTransporterList() {
      this.setLoading(true);

      await this.showTransporterList({
        ...this.options,
        keyword: this.search,
        ...this.loadOrdersFilter,
        u_transp: this.filterUTranspNome,
      });

      this.setLoading(false);
    },
    async emitDownloadPdf(withFt = false) {
      const type = withFt ? 'transporterFt' : 'transporter';

      this.downloadPdf({ ...this.options, keyword: this.search, type });

      this.setLoading(false);
    },
    async inlineSave(obj) {
      this.setLoading(true);

      await this.updateOrdersListValue({ ...obj, type: 'transporter' });

      this.setLoading(false);

      await this.getTransporterList();
    },
    async openTrailers(uTransp, val) {
      this.setLoading(true);

      await this.showTrailers(uTransp);

      this.active.trailer = val;

      this.setLoading(false);
    },
    async openPlates(uTransp, val) {
      await this.showPlates(uTransp);

      this.active.plate = val;
    },
  },
  async mounted() {
    await this.fetchServerTime();
  },
};
</script>
