<template>
  <div v-if="loadOrdersFilter.startDate && loadOrdersFilter.endDate">
    <load-orders-filter
      @filter-load-orders="getProviderList"
      @download-pdf="emitDownloadPdf"
      listType="provider"
      isList
      hasPdf
      :excelData="providerList"
      :excelFields="excelFields"
    />
    <v-card elevation="24" class="mt-1">
      <v-card-title>
      <v-spacer/>
      <v-btn text class="mb-6 mr-3" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
      <v-icon class="mb-6 mr-3" @click="getProviderList">{{ icons.mdiReload }}</v-icon>
      <v-text-field
        v-model="search"
        :prepend-inner-icon="icons.mdiMagnify"
        :label="$t('aSearch')"
        counter="50"
        maxlength="50"
        clearable
        outlined
        rounded
        dense
        @change="getProviderList"
      />
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          class="list"
          :headers="headers"
          :items="providerList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="providerTotal"
          :mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :expanded.sync="expanded"
          item-key="bostamp"
          show-expand
          height="72vh"
          fixed-header
          :item-class="rowClasses"
        >
          <template v-slot:header.uMataFilter>
            <wot-filter-menu :items="getProviderListValue('u_mata')" fieldName="u_mata"/>
          </template>
          <template v-slot:header.uTiposFilter>
            <wot-filter-menu :items="getProviderListValue('u_tipos')" fieldName="u_tipos"/>
          </template>
          <template v-slot:item.dataobra="{ value }">{{ formatUnix(value) }}</template>
          <template v-slot:item.u_mata="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openWoods(value)"
              @save="updateUMata({
                index, value: active.wood, field: 'u_mata', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">{{ value }}</wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_mata">
                  <v-autocomplete
                    v-model="active.wood"
                    :items="woods"
                    :label="$t('fields.u_mata')"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                    clearable
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_cepos="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_cepos', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_cepos" rules="required">
                  <v-text-field
                    v-model="val"
                    :label="`${$t('fields.u_cepos')} *`"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="40"
                    maxlength="40"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_quant2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_quant2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="`${value} ${item.unidade}`">
                {{ item.u_quant2 }} {{ item.unidade }}
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_quant2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_quant2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_comiss="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_comiss', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_comiss')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.total="{ item }">
            {{ $n(item.u_quant2 * item.u_comiss, 'currency') }}
          </template>
          <template v-slot:item.u_ftfornec="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_ftfornec', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value || 'truthy'">
                <span v-if="value">{{ value }}</span>
                <span class="info--text" v-else>{{ $t('noFt') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_ftfornec">
                  <v-text-field
                    v-model="val"
                    :label="$t('fields.u_ftfornec')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="15"
                    maxlength="15"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_kickback="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_kickback', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_kickback" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_kickback')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_agent="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="openUAgent(value)"
              @save="inlineSave({
                index, value: active.u_agent, field: 'u_agent', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_agent">
                  <v-autocomplete
                    v-model="active.u_agent"
                    :items="agents"
                    item-text="nome2"
                    item-value="nome2"
                    :label="`${$t('fields.u_agent')} *`"
                    :prepend-icon="icons.mdiAccountSearch"
                    :error-messages="errors"
                    single-line
                    autofocus
                  ></v-autocomplete>
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn
              color="primary"
              icon
              :to="{ name: 'editLoadOrder', params: { bostamp: item.bostamp }}"
              target="_blank"
            >
              <v-icon>{{ icons.mdiFileDocumentEditOutline }}</v-icon>
            </v-btn>
            <v-badge v-if="item.anexos > 0" :content="item.anexos" color="secondary" overlap>
              <v-btn icon @click="expand(true)" v-if="!isExpanded">
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
              <v-btn icon @click="expand(false)" v-if="isExpanded">
                <v-icon>{{ icons.mdiChevronUp }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <attachments
                class="mt-3 mb-3 text-right"
                :bostamp="item.bostamp"
                @open-pdf="openPdf"
                @reload-list="getProviderList"
              />
            </td>
          </template>
          <template slot="body.append">
            <tr>
              <td colspan="8"></td>
              <td v-for="(item, index) in sumProviderQuant" :key="index">
                {{ displayTotal(item) }}
              </td>
              <td class="text-right">{{ $n(sumProviderTotal, 'currency') }}</td>
              <td colspan="3"></td>
            </tr>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ $t('pageText', [pageStart, pageStop, itemsLength]) }} <br />
            {{ $t('loadOrdersCount', [itemsLength - filteredStamps.length]) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <wot-send-list
      v-if="emailDialog"
      :count="providerTotal"
      :emails="emails"
      :options="options"
      :keyword="search"
      type="provider"
      :bostamps="bostamps"
    />
    <v-dialog
      v-model="pdfDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="pdfDialog = false"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="pdfDialog = false"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
          <v-toolbar-title>{{ titleDialog }}</v-toolbar-title>
        </v-toolbar>
        <vue-pdf-app :pdf="fileName"></vue-pdf-app>
      </v-card>
    </v-dialog>
    <link rel="resource" type="application/l10n" :href="`/pdf.js/${lang}/viewer.properties`">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';
import {
  mdiMagnify,
  mdiFileDocumentEditOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiReload,
  mdiClose,
} from '@mdi/js';
import { itemsPerPage } from '@/resources/options';
import { providerListHeaders } from '@/resources/headers';
import functions from '@/resources/functions';

export default {
  metaInfo() {
    return {
      title: this.$t('providerList'),
    };
  },
  mixins: [functions],
  components: {
    ValidationProvider,
    LoadOrdersFilter: () => import(/* webpackChunkName: "filter" */ '@/components/filters/LoadOrdersFilter.vue'),
    WotListItem: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotListItem.vue'),
    WotFilterMenu: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotFilterMenu.vue'),
    WotSendList: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotSendList.vue'),
    Attachments: () => import(/* webpackChunkName: "attachment" */ '../Attachments.vue'),
    VuePdfApp: () => import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app'),
  },
  data() {
    return {
      lang: process.env.VUE_APP_I18N_LOCALE,
      pdfKey: 0,
      itemsPerPage,
      search: '',
      options: {},
      headers: providerListHeaders,
      expanded: [],
      titleDialog: '',
      fileName: null,
      pdfDialog: false,
      icons: {
        mdiMagnify,
        mdiFileDocumentEditOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiReload,
        mdiClose,
      },
      active: {
        wood: '',
        u_agent: '',
      },
      excelFields: {
        [this.$t('fields.obrano')]: 'obrano',
        [this.$t('fields.dataobra')]: {
          field: 'dataobra',
          callback: (value) => this.formatUnix(value),
        },
        [this.$t('fields.u_cepos')]: 'u_cepos',
        [this.$t('fields.u_matric')]: 'u_matric',
        [this.$t('fields.u_mata')]: 'u_mata',
        [this.$t('fields.u_tipos')]: 'u_tipos',
        [this.$t('fields.u_quant2')]: 'u_quantity',
        [this.$t('fields.u_comiss')]: 'u_comiss',
        [this.$t('fields.total')]: 'total',
      },
    };
  },
  computed: {
    ...mapGetters('loadOrders', ['providerList', 'providerTotal', 'sumProviderTotal', 'filterUNmfornecEmail', 'emailDialog', 'sumProviderQuant', 'loadOrdersFilter', 'getProviderListValue', 'woodProvider', 'woods', 'providers']),
    ...mapGetters(['isLoading']),
    ...mapFields('loadOrders', {
      uMataFilter: 'filterMenu.u_mata',
      uTiposFilter: 'filterMenu.u_tipos',
    }),
    ...mapFields('loadOrders', ['filteredStamps']),
    emails() {
      return this.explodeEmails(this.filterUNmfornecEmail);
    },
    bostamps() {
      return this.providerList.filter(({ anexos }) => anexos > 0)
        .map(({ bostamp }) => bostamp);
    },
    agents() {
      return this.providers.filter(({ nome2 }) => nome2 !== '');
    },
  },
  watch: {
    options: {
      handler() {
        this.getProviderList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchServerTime']),
    ...mapActions('loadOrders', ['showProviderList', 'downloadPdf', 'updateOrdersListValue', 'showWoods', 'showWoodProvider']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setFilteredStamps']),
    clearFilters() {
      this.uMataFilter = [];
      this.uTiposFilter = [];
    },
    rowClasses(item) {
      const stamps = this.filteredStamps.slice();

      if (
        this.uMataFilter.includes(item.u_mata)
        || this.uTiposFilter.includes(item.u_tipos)
      ) {
        if (!stamps.includes(item.bostamp)) {
          this.setFilteredStamps([...stamps, item.bostamp]);
        }

        return 'd-none';
      }

      if (stamps.includes(item.bostamp)) {
        const index = stamps.indexOf(item.bostamp);

        stamps.splice(index, 1);

        this.setFilteredStamps(stamps);
      }

      return '';
    },
    openPdf({ fileName, title }) {
      this.pdfKey += 1;

      this.titleDialog = title;
      this.fileName = fileName;

      this.pdfDialog = true;
    },
    async getProviderList() {
      this.setLoading(true);

      await this.showProviderList({ ...this.options, keyword: this.search });

      this.setLoading(false);
    },
    async emitDownloadPdf() {
      this.setLoading(true);

      this.downloadPdf({ ...this.options, keyword: this.search, type: 'provider' });

      this.setLoading(false);
    },
    async inlineSave(obj) {
      this.setLoading(true);

      await this.updateOrdersListValue({ ...obj, type: 'provider' });

      this.setLoading(false);

      await this.getProviderList();
    },
    async openWoods(val) {
      this.setLoading(true);

      await this.showWoods();

      this.active.wood = val;

      this.setLoading(false);
    },
    async updateUMata(obj) {
      this.setLoading(true);

      const { value } = obj;

      this.updateOrdersListValue({ ...obj, type: 'provider' });

      if (value) {
        await this.showWoodProvider(value);

        await this.updateOrdersListValue({
          ...obj, field: 'u_nmfornec', value: this.woodProvider.nome, com: false,
        });
      }

      this.setLoading(false);
    },
    async openUAgent(val) {
      this.setLoading(true);

      this.active.u_agent = val;

      this.setLoading(false);
    },
  },
  async mounted() {
    await this.fetchServerTime();
  },
};
</script>
