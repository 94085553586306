<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  methods: {
    ...mapActions('clients', ['showClients']),
    ...mapActions('loadOrders', ['showTransporters', 'showCargoTypes', 'showUnits', 'showProviders', 'showTeams', 'showWoods', 'showTasks', 'showUploadAddresses']),
    ...mapMutations(['setLoading']),
  },
  async mounted() {
    this.setLoading(true);

    await this.showClients();
    await this.showTransporters();
    await this.showCargoTypes();
    await this.showUnits();
    await this.showProviders();
    await this.showTeams();
    await this.showTasks();
    await this.showUploadAddresses();
    await this.showWoods();

    this.setLoading(false);
  },
};
</script>
