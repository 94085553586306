import i18n from '@/plugins/i18n';

const loadOrdersHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_transp'),
    value: 'u_transp',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'uTranspFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.tc('plate', 2),
    value: 'plate',
    sortable: false,
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.client'),
    value: 'nome',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'nomeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_carga'),
    value: 'u_carga',
    class: 'primary white--text',
    width: 200,
  },
  {
    value: 'uCargaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('otherData'),
    value: 'u_carga2',
    class: 'primary white--text',
  },
  {
    value: 'uCarga2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_descarga'),
    value: 'u_descarga',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uDescargaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('otherData'),
    value: 'u_descarg2',
    class: 'primary white--text',
  },
  {
    value: 'uDescarg2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.unidade'),
    value: 'unidade',
    class: 'primary white--text',
  },
  {
    value: 'unidadeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prcl'),
    value: 'u_prcl',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_bonus'),
    value: 'u_bonus',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    value: 'nftFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_fttrans'),
    value: 'u_fttrans',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft1',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    value: 'nft1Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prtransp'),
    value: 'u_prtransp',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_fttransp'),
    value: 'u_fttransp',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_comiss'),
    value: 'u_comiss',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.atcodeid'),
    value: 'at_document',
    class: 'primary white--text',
    sortable: false,
    width: 150,
  },
];

const loadOrdersGlobalListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.atcodeid'),
    value: 'at_document',
    class: 'primary white--text',
    sortable: false,
    width: 150,
  },
  {
    text: i18n.t('fields.u_transp'),
    value: 'u_transp',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'uTranspFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.tc('plate', 2),
    value: 'plate',
    sortable: false,
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.client'),
    value: 'nome',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'nomeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_carga'),
    value: 'u_carga',
    class: 'primary white--text',
    width: 200,
  },
  {
    value: 'uCargaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('otherData'),
    value: 'u_carga2',
    class: 'primary white--text',
  },
  {
    value: 'uCarga2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_descarga'),
    value: 'u_descarga',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uDescargaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('otherData'),
    value: 'u_descarg2',
    class: 'primary white--text',
  },
  {
    value: 'uDescarg2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.unidade'),
    value: 'unidade',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'unidadeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_mata'),
    value: 'u_mata',
    class: 'primary white--text',
    width: 200,
  },
  {
    value: 'uMataFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_fttrans'),
    value: 'u_fttrans',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft1',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prcl'),
    value: 'u_prcl',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_bonus'),
    value: 'u_bonus',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prtransp'),
    value: 'u_prtransp',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_fttransp'),
    value: 'u_fttransp',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_comiss'),
    value: 'u_comiss',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_agent'),
    value: 'u_agent',
    class: 'primary white--text',
    width: 250,
  },
  {
    text: i18n.t('fields.u_kickback'),
    value: 'u_kickback',
    class: 'primary white--text',
    align: 'right',
  },
  {
    text: i18n.t('fields.u_interme2'),
    value: 'u_interme2',
    class: 'primary white--text',
    width: 250,
  },
  {
    text: i18n.t('fields.u_vconsul2'),
    value: 'u_vconsul2',
    class: 'primary white--text',
    align: 'right',
  },
  {
    text: i18n.t('fields.u_interme3'),
    value: 'u_interme3',
    class: 'primary white--text',
    width: 250,
  },
  {
    text: i18n.t('fields.u_vconsul3'),
    value: 'u_vconsul3',
    class: 'primary white--text',
    align: 'right',
  },
  {
    text: i18n.t('fields.final'),
    value: 'final',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.profit'),
    value: 'lucro',
    align: 'right',
    class: ['primary', 'white--text'],
    cellClass: [],
    width: 150,
  },
  {
    text: i18n.t('fields.u_equipa1'),
    value: 'u_equipa1',
    class: 'primary white--text',
  },
  {
    value: 'uEquipa1Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tarefa1'),
    value: 'u_tarefa1',
    class: 'primary white--text',
  },
  {
    value: 'uTarefa1Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prc1'),
    value: 'u_prc1',
    class: 'primary white--text',
  },
  {
    value: 'uPrc1Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_equipa2'),
    value: 'u_equipa2',
    class: 'primary white--text',
  },
  {
    value: 'uEquipa2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tarefa2'),
    value: 'u_tarefa2',
    class: 'primary white--text',
  },
  {
    value: 'uTarefa2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prc2'),
    value: 'u_prc2',
    class: 'primary white--text',
  },
  {
    value: 'uPrc2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_equipa3'),
    value: 'u_equipa3',
    class: 'primary white--text',
  },
  {
    value: 'uEquipa3Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tarefa3'),
    value: 'u_tarefa3',
    class: 'primary white--text',
  },
  {
    value: 'uTarefa3Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prc3'),
    value: 'u_prc3',
    class: 'primary white--text',
  },
  {
    value: 'uPrc3Filter',
    class: 'primary white--text',
    sortable: false,
  },
];

const certifiedMaterialListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.tc('plate', 2),
    value: 'plate',
    sortable: false,
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.client'),
    value: 'nome',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'nomeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_prcl'),
    value: 'u_prcl',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_bonus'),
    value: 'u_bonus',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_fttrans'),
    value: 'u_fttrans',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft1',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.lobs'),
    value: 'lobs',
    class: 'primary white--text',
    sortable: false,
  },
  {
    value: 'lobsFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_mata'),
    value: 'u_mataalt',
    class: 'primary white--text',
    width: 200,
  },
  {
    value: 'uMataFilter',
    class: 'primary white--text',
    sortable: false,
    align: 'right',
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.atcodeid'),
    value: 'at_document',
    class: 'primary white--text',
    align: 'right',
    sortable: false,
    width: 150,
  },
];

const teamsListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_tarefa'),
    value: 'u_tarefa',
    class: 'primary white--text',
    sortable: false,
  },
  {
    value: 'uTarefaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_matric'),
    value: 'u_matric',
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.carga'),
    value: 'carga',
    class: 'primary white--text',
    sortable: false,
    width: 225,
  },
  {
    value: 'cargaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_prc'),
    value: 'u_prc',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    value: 'uPrcFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.total'),
    value: 'total',
    align: 'right',
    class: 'primary white--text',
    width: 150,
    sortable: false,
  },
  {
    text: i18n.t('fields.u_equipa'),
    value: 'list',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_ftequip'),
    value: 'u_ftequip',
    class: 'primary white--text',
    sortable: false,
  },
];

const providerListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_matric'),
    value: 'u_matric',
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.u_mata'),
    value: 'u_mata',
    class: 'primary white--text',
    width: 200,
  },
  {
    value: 'uMataFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_comiss'),
    value: 'u_comiss',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.total'),
    value: 'total',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_ftfornec'),
    value: 'u_ftfornec',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_agent'),
    value: 'u_agent',
    class: 'primary white--text',
    width: 250,
  },
  {
    text: i18n.t('fields.u_kickback'),
    value: 'u_kickback',
    class: 'primary white--text',
    align: 'right',
  },
];

const agentListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_matric'),
    value: 'u_matric',
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.u_mata'),
    value: 'u_mata',
    class: 'primary white--text',
    width: 200,
  },
  {
    value: 'uMataFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_kickback'),
    value: 'u_kickback',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.total'),
    value: 'total',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_uftagent'),
    value: 'u_uftagent',
    class: 'primary white--text',
  },
];

const clientListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.client'),
    value: 'nome',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'nomeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },

  {
    text: i18n.t('fields.u_matric'),
    value: 'u_matric',
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.carga'),
    value: 'carga',
    class: 'primary white--text',
    sortable: false,
    width: 225,
  },
  {
    text: i18n.t('fields.descarga'),
    value: 'descarga',
    class: 'primary white--text',
    sortable: false,
    width: 150,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_fttrans'),
    value: 'u_fttrans',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.total'),
    value: 'u_fttrans_total',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft1',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: `${i18n.t('regularized')}?`,
    value: 'nft1_regul',
    class: 'primary white--text',
    sortable: false,
    align: 'center',
  },
  {
    text: i18n.t('fields.u_prcl'),
    value: 'u_prcl',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: `${i18n.t('certified')}?`,
    value: 'certified',
    class: 'primary white--text',
    sortable: false,
    align: 'center',
  },
  {
    text: i18n.t('fields.total'),
    value: 'u_prcl_total',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.nft'),
    value: 'nft',
    align: 'right',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: `${i18n.t('regularized')}?`,
    value: 'nft_regul',
    class: 'primary white--text',
    sortable: false,
    align: 'center',
  },
  {
    text: i18n.t('fields.final'),
    value: 'total_final',
    class: 'primary white--text',
    sortable: false,
    align: 'right',
  },
];

const transporterListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_transp'),
    value: 'u_transp',
    class: 'primary white--text',
    width: 225,
  },
  {
    text: i18n.tc('plate', 2),
    value: 'plate',
    sortable: false,
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.client'),
    value: 'nome',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'nomeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.carga'),
    value: 'carga',
    class: 'primary white--text',
    sortable: false,
    width: 225,
  },
  {
    text: i18n.t('fields.descarga'),
    value: 'descarga',
    class: 'primary white--text',
    sortable: false,
    width: 150,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_prtransp'),
    value: 'u_prtransp',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.totalNVat'),
    value: 'totalNVat',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.vat'),
    value: 'vat',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.totalVat'),
    value: 'totalVat',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_fttransp'),
    value: 'u_fttransp',
    class: 'primary white--text',
  },
];

const parkListHeaders = [
  {
    value: 'data-table-expand',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.obrano'),
    value: 'obrano',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.dataobra'),
    value: 'dataobra',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_transp'),
    value: 'u_transp',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'uTranspFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_matric'),
    value: 'u_matric',
    class: 'primary white--text',
    width: 100,
  },
  {
    text: i18n.t('fields.client'),
    value: 'nome',
    class: 'primary white--text',
    width: 225,
  },
  {
    value: 'nomeFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_cepos'),
    value: 'u_cepos',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
    class: 'primary white--text',
    width: 150,
  },
  {
    value: 'uTiposFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.carga'),
    value: 'carga',
    class: 'primary white--text',
    sortable: false,
    width: 225,
  },
  {
    value: 'cargaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.descarga'),
    value: 'descarga',
    class: 'primary white--text',
    sortable: false,
    width: 150,
  },
  {
    value: 'descargaFilter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_quant2'),
    value: 'u_quant2',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_comiss'),
    value: 'u_comiss',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_fttrans'),
    value: 'u_fttrans',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_prcl'),
    value: 'u_prcl',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_prtransp'),
    value: 'u_prtransp',
    align: 'right',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.u_fttransp'),
    value: 'u_fttransp',
    class: 'primary white--text',
  },
  {
    text: i18n.t('fields.final'),
    value: 'final',
    align: 'right',
    class: 'primary white--text',
    width: 150,
  },
  {
    text: i18n.t('fields.profit'),
    value: 'lucro',
    align: 'right',
    class: ['primary', 'white--text'],
    cellClass: [],
    width: 150,
  },
  {
    text: i18n.t('fields.u_equipa1'),
    value: 'u_equipa1',
    class: 'primary white--text',
  },
  {
    value: 'uEquipa1Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tarefa1'),
    value: 'u_tarefa1',
    class: 'primary white--text',
  },
  {
    value: 'uTarefa1Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prc1'),
    value: 'u_prc1',
    class: 'primary white--text',
  },
  {
    value: 'uPrc1Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_equipa2'),
    value: 'u_equipa2',
    class: 'primary white--text',
  },
  {
    value: 'uEquipa2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tarefa2'),
    value: 'u_tarefa2',
    class: 'primary white--text',
  },
  {
    value: 'uTarefa2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prc2'),
    value: 'u_prc2',
    class: 'primary white--text',
  },
  {
    value: 'uPrc2Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_equipa3'),
    value: 'u_equipa3',
    class: 'primary white--text',
  },
  {
    value: 'uEquipa3Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_tarefa3'),
    value: 'u_tarefa3',
    class: 'primary white--text',
  },
  {
    value: 'uTarefa3Filter',
    class: 'primary white--text',
    sortable: false,
  },
  {
    text: i18n.t('fields.u_prc3'),
    value: 'u_prc3',
    class: 'primary white--text',
  },
  {
    value: 'uPrc3Filter',
    class: 'primary white--text',
    sortable: false,
  },
];

const deliveryNoteLines = [
  {
    text: i18n.t('fields.u_tipos'),
    value: 'u_tipos',
  },
  {
    text: i18n.t('fields.unidade'),
    value: 'unidade',
  },
  {
    text: i18n.t('fields.u_prcl'),
    value: 'u_prcl',
  },
  {
    text: i18n.t('fields.u_comiss'),
    value: 'u_comiss',
  },
  {
    text: i18n.t('fields.u_prtransp'),
    value: 'u_prtransp',
  },
  {
    text: i18n.t('fields.u_fttrans'),
    value: 'u_fttrans',
  },
];

export {
  loadOrdersHeaders,
  teamsListHeaders,
  providerListHeaders,
  agentListHeaders,
  transporterListHeaders,
  deliveryNoteLines,
  parkListHeaders,
  clientListHeaders,
  loadOrdersGlobalListHeaders,
  certifiedMaterialListHeaders,
};
