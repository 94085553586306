<template>
  <div v-if="loadOrdersFilter.startDate && loadOrdersFilter.endDate">
    <load-orders-filter @filter-load-orders="getParkList" listType="park"/>
    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-btn text class="mb-6 mr-3" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
        <v-icon class="mb-6 mr-3" @click="getParkList">{{ icons.mdiReload }}</v-icon>
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getParkList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          class="list"
          :headers="headers"
          :items="parkList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="parkTotal"
          :mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :expanded.sync="expanded"
          item-key="bostamp"
          show-expand
          height="72vh"
          fixed-header
          :item-class="rowClasses"
        >
          <template v-slot:header.uTranspFilter>
            <wot-filter-menu :items="getParkListValue('u_transp')" fieldName="u_transp"/>
          </template>
          <template v-slot:header.nomeFilter>
            <wot-filter-menu :items="getParkListValue('nome')" fieldName="nome"/>
          </template>
          <template v-slot:header.uTiposFilter>
            <wot-filter-menu :items="getParkListValue('u_tipos')" fieldName="u_tipos"/>
          </template>
          <template v-slot:header.cargaFilter>
            <wot-filter-menu :items="getParkListValue('carga')" fieldName="carga"/>
          </template>
          <template v-slot:header.descargaFilter>
            <wot-filter-menu :items="getParkListValue('descarga')" fieldName="descarga"/>
          </template>
          <template v-slot:header.uFttranspFilter>
            <wot-filter-menu :items="getParkListValue('u_fttransp')" fieldName="u_fttransp"/>
          </template>
          <template v-slot:item.final="{ value }">
            <span :class="value < 0 ? 'error--text' : ''">{{ $n(value, 'currency') }}</span>
          </template>
          <template v-slot:item.lucro="{ value }">
            <span :class="value < 0 ? 'error--text' : ''">{{ $n(value, 'currency') }}</span>
          </template>
          <template v-slot:header.uEquipa1Filter>
            <wot-filter-menu :items="getParkListValue('u_equipa1')" fieldName="u_equipa1"/>
          </template>
          <template v-slot:header.uTarefa1Filter>
            <wot-filter-menu :items="getParkListValue('u_tarefa1')" fieldName="u_tarefa1"/>
          </template>
          <template v-slot:header.uPrc1Filter>
            <wot-filter-menu
              :items="getParkListValue('u_prc1')"
              fieldName="u_prc1"
              format="currency"
            />
          </template>
          <template v-slot:header.uEquipa2Filter>
            <wot-filter-menu :items="getParkListValue('u_equipa2')" fieldName="u_equipa2"/>
          </template>
          <template v-slot:header.uTarefa2Filter>
            <wot-filter-menu :items="getParkListValue('u_tarefa2')" fieldName="u_tarefa2"/>
          </template>
          <template v-slot:header.uPrc2Filter>
            <wot-filter-menu
              :items="getParkListValue('u_prc2')"
              fieldName="u_prc2"
              format="currency"
            />
          </template>
          <template v-slot:header.uEquipa3Filter>
            <wot-filter-menu :items="getParkListValue('u_equipa3')" fieldName="u_equipa3"/>
          </template>
          <template v-slot:header.uTarefa3Filter>
            <wot-filter-menu :items="getParkListValue('u_tarefa3')" fieldName="u_tarefa3"/>
          </template>
          <template v-slot:header.uPrc3Filter>
            <wot-filter-menu
              :items="getParkListValue('u_prc3')"
              fieldName="u_prc3"
              format="currency"
            />
          </template>
          <template v-slot:item.dataobra="{ value }">{{ formatUnix(value) }}</template>
          <template v-slot:item.u_cepos="{ index, value, item }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_cepos', bostamp: item.bostamp
              })"
            >
              {{ value }}
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_cepos" rules="required">
                  <v-text-field
                    v-model="val"
                    :label="`${$t('fields.u_cepos')} *`"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                    counter="40"
                    maxlength="40"
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_quant2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_quant2', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="`${value} ${item.unidade}`">
                {{ item.u_quant2 }} {{ item.unidade }}
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_quant2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_quant2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_comiss="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_comiss', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_comiss" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_comiss')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_fttrans="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_fttrans', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_fttrans" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_fttrans')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prcl="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prcl', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prcl" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prcl')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_prtransp="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prtransp', bostamp: item.bostamp
              })"
            >
              <wot-list-item :item="value">
                <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              </wot-list-item>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prtransp" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prtransp')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_fttransp="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noFt') }}</span>
          </template>
          <template v-slot:item.u_equipa1="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noUEquipa') }}</span>
          </template>
          <template v-slot:item.u_tarefa1="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noUTarefa') }}</span>
          </template>
          <template v-slot:item.u_prc1="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prc1', bostamp: item.bostamp
              })"
            >
              <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prc1" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prc1')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_equipa2="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noUEquipa') }}</span>
          </template>
          <template v-slot:item.u_tarefa2="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noUTarefa') }}</span>
          </template>
          <template v-slot:item.u_prc2="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prc2', bostamp: item.bostamp
              })"
            >
              <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prc2" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prc2')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.u_equipa3="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noUEquipa') }}</span>
          </template>
          <template v-slot:item.u_tarefa3="{ value }">
            <span v-if="value">{{ value }}</span>
            <span class="info--text" v-else>{{ $t('noUTarefa') }}</span>
          </template>
          <template v-slot:item.u_prc3="{ index, item, value }">
            <v-edit-dialog
              large
              persistent
              :cancel-text="$t('close')"
              :save-text="$t('save')"
              @open="val = value"
              @save="inlineSave({
                index, value: val, field: 'u_prc3', bostamp: item.bostamp
              })"
            >
              <span :class="!value ? 'warning--text' : ''">{{ $n(value, 'currency') }}</span>
              <template v-slot:input>
                <validation-provider v-slot="{ errors }" name="u_prc3" rules="max_value:99999">
                  <v-text-field
                    v-model.number="val"
                    type="number"
                    :label="$t('fields.u_prc3')"
                    :prepend-icon="icons.mdiPencilOutline"
                    :error-messages="errors"
                    single-line
                    autofocus
                  />
                </validation-provider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn
              color="primary"
              icon
              :to="{ name: 'editLoadOrder', params: { bostamp: item.bostamp }}"
              target="_blank"
            >
              <v-icon>{{ icons.mdiFileDocumentEditOutline }}</v-icon>
            </v-btn>
            <v-badge v-if="item.anexos > 0" :content="item.anexos" color="secondary" overlap>
              <v-btn icon @click="expand(true)" v-if="!isExpanded">
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
              <v-btn icon @click="expand(false)" v-if="isExpanded">
                <v-icon>{{ icons.mdiChevronUp }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <attachments
                class="mt-3 mb-3 text-right"
                :bostamp="item.bostamp"
                @open-pdf="openPdf"
                @reload-list="getParkList"
              />
            </td>
          </template>
          <template slot="body.append">
            <tr>
              <td colspan="22"></td>
              <td
                v-if="!roles.includes('ELucro')"
                :class="sumParkLucro < 0 ? 'error--text text-right' : 'text-right'"
              >
                {{ $n(sumParkLucro, 'currency') }}
              </td>
              <td colspan="18"></td>
            </tr>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ $t('pageText', [pageStart, pageStop, itemsLength]) }} <br />
            {{ $t('loadOrdersCount', [itemsLength - filteredStamps.length]) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="pdfDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="pdfDialog = false"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="pdfDialog = false"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
          <v-toolbar-title>{{ titleDialog }}</v-toolbar-title>
        </v-toolbar>
        <vue-pdf-app :pdf="fileName"></vue-pdf-app>
      </v-card>
    </v-dialog>
    <link rel="resource" type="application/l10n" :href="`/pdf.js/${lang}/viewer.properties`">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';
import {
  mdiMagnify,
  mdiFileDocumentEditOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiReload,
  mdiClose,
} from '@mdi/js';
import { itemsPerPage } from '@/resources/options';
import { parkListHeaders } from '@/resources/headers';
import functions from '@/resources/functions';

export default {
  metaInfo() {
    return {
      title: this.$t('entriesList'),
    };
  },
  mixins: [functions],
  components: {
    ValidationProvider,
    LoadOrdersFilter: () => import(/* webpackChunkName: "filter" */ '@/components/filters/LoadOrdersFilter.vue'),
    VuePdfApp: () => import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app'),
    WotListItem: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotListItem.vue'),
    WotFilterMenu: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotFilterMenu.vue'),
    Attachments: () => import(/* webpackChunkName: "attachment" */ '../Attachments.vue'),
  },
  data: () => ({
    lang: process.env.VUE_APP_I18N_LOCALE,
    pdfKey: 0,
    itemsPerPage,
    search: '',
    options: {},
    headers: parkListHeaders,
    expanded: [],
    titleDialog: '',
    fileName: null,
    pdfDialog: false,
    icons: {
      mdiMagnify,
      mdiFileDocumentEditOutline,
      mdiChevronDown,
      mdiChevronUp,
      mdiReload,
      mdiClose,
    },
  }),
  computed: {
    ...mapGetters('auth', ['roles']),
    ...mapGetters('loadOrders', ['parkList', 'parkTotal', 'loadOrdersFilter', 'getParkListValue', 'sumParkLucro']),
    ...mapGetters(['isLoading']),
    ...mapFields('loadOrders', {
      uTranspFilter: 'filterMenu.u_transp',
      nomeFilter: 'filterMenu.nome',
      uTiposFilter: 'filterMenu.u_tipos',
      cargaFilter: 'filterMenu.carga',
      descargaFilter: 'filterMenu.descarga',
      uFttranspFilter: 'filterMenu.u_fttransp',
      uEquipa1Filter: 'filterMenu.u_equipa1',
      uTarefa1Filter: 'filterMenu.u_tarefa1',
      uPrc1Filter: 'filterMenu.u_prc1',
      uEquipa2Filter: 'filterMenu.u_equipa2',
      uTarefa2Filter: 'filterMenu.u_tarefa2',
      uPrc2Filter: 'filterMenu.u_prc2',
      uEquipa3Filter: 'filterMenu.u_equipa3',
      uTarefa3Filter: 'filterMenu.u_tarefa3',
      uPrc3Filter: 'filterMenu.u_prc3',
    }),
    ...mapFields('loadOrders', ['filteredStamps']),
  },
  watch: {
    options: {
      handler() {
        this.getParkList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchServerTime']),
    ...mapActions('loadOrders', ['showParkList', 'updateOrdersListValue']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setFilteredStamps']),
    clearFilters() {
      this.uTranspFilter = [];
      this.nomeFilter = [];
      this.uTiposFilter = [];
      this.cargaFilter = [];
      this.descargaFilter = [];
      this.uFttranspFilter = [];
      this.uEquipa1Filter = [];
      this.uTarefa1Filter = [];
      this.uPrc1Filter = [];
      this.uEquipa2Filter = [];
      this.uTarefa2Filter = [];
      this.uPrc2Filter = [];
      this.uEquipa3Filter = [];
      this.uTarefa3Filter = [];
      this.uPrc3Filter = [];
    },
    rowClasses(item) {
      const stamps = this.filteredStamps.slice();

      if (
        this.uTranspFilter.includes(item.u_transp)
        || this.nomeFilter.includes(item.nome)
        || this.uTiposFilter.includes(item.u_tipos)
        || this.cargaFilter.includes(item.carga)
        || this.descargaFilter.includes(item.descarga)
        || this.uFttranspFilter.includes(item.u_fttransp)
        || this.uEquipa1Filter.includes(item.u_equipa1)
        || this.uTarefa1Filter.includes(item.u_tarefa1)
        || this.uPrc1Filter.includes(item.u_prc1)
        || this.uEquipa2Filter.includes(item.u_equipa2)
        || this.uTarefa2Filter.includes(item.u_tarefa2)
        || this.uPrc2Filter.includes(item.u_prc2)
        || this.uEquipa3Filter.includes(item.u_equipa3)
        || this.uTarefa3Filter.includes(item.u_tarefa3)
        || this.uPrc3Filter.includes(item.u_prc3)
      ) {
        if (!stamps.includes(item.bostamp)) {
          this.filteredStamps = [...stamps, item.bostamp];
        }

        return 'd-none';
      }

      if (stamps.includes(item.bostamp)) {
        const index = stamps.indexOf(item.bostamp);

        stamps.splice(index, 1);

        this.setFilteredStamps(stamps);
      }

      return '';
    },
    async getParkList() {
      this.setLoading(true);

      await this.showParkList({ ...this.options, keyword: this.search, direction: 'entries' });

      this.setLoading(false);
    },
    async inlineSave(obj) {
      this.setLoading(true);

      await this.updateOrdersListValue({ ...obj, type: 'park' });

      this.setLoading(false);

      await this.getParkList();
    },
    openPdf({ fileName, title }) {
      this.pdfKey += 1;

      this.titleDialog = title;
      this.fileName = fileName;

      this.pdfDialog = true;
    },
  },
  async mounted() {
    await this.fetchServerTime();

    if (this.roles.includes('ELucro')) {
      const index = this.headers.findIndex(({ value }) => value === 'lucro');

      this.headers[index].class.push('d-none');
      this.headers[index].cellClass.push('d-none');
    }
  },
};
</script>
